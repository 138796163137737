import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/listPage/:id',
        name: 'Trends',
        component: () =>
            import ( /* webpackChunkName: "trends" */ '../views/Trends.vue'),
    },
    {
        path: '/detail/:id',
        name: 'About',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue'),
    },
    // {
    //     path: '/detail/:id',
    //     name: 'Detail',
    //     component: () =>
    //         import ( /* webpackChunkName: "detail" */ '../views/Detail.vue'),
    // },
    {
        path: '/prodetail/:id/:infoId',
        name: 'ProDetail',
        component: () =>
            import ( /* webpackChunkName: "detail" */ '../views/ProDetail.vue'),
    },
    {
        path: '/product/:id',
        name: 'Product',
        component: () =>
            import ( /* webpackChunkName: "Product" */ '../views/Product.vue'),
    }

]

const router = new VueRouter({
    mode: 'history',
    routes

})

export default router
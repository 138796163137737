var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news-area"},[_vm._m(0),_c('div',{staticClass:"journalism"},_vm._l((_vm.list),function(New,n){return _c('div',{key:n,staticClass:"news"},[(n < 3)?_c('div',{staticClass:"jour-new"},[_c('img',{attrs:{"src":_vm.axios.defaults.baseURL + New.coverImg,"alt":""}}),_c('div',{staticClass:"jour-info"},[_c('div',{staticClass:"nadetail"},[_c('p',{staticClass:"name"},[_vm._v(" "+_vm._s(New.title)+" ")]),_c('p',{staticClass:"date"},[_vm._v(_vm._s(New.releaseTime))]),_c('p',{staticClass:"intro"},[_vm._v(_vm._s(New.introduce))])]),_c('div',{staticClass:"more"},[_c('router-link',{class:{
                button: true,
                second: n == 1,
              },attrs:{"target":"_blank","tag":"a","to":{
                name: 'ProDetail',
                params: {
                  id: New.navMenuId,
                  infoId: New.id,
                },
              }}},[_vm._v(" more ")])],1)])]):_vm._e()])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('p',{staticClass:"chinese"},[_vm._v("Applications")]),_c('div',{staticClass:"english"})])}]

export { render, staticRenderFns }
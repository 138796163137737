<template>
  <div class="news-area">
    <div class="title">
      <p class="chinese">Applications</p>
      <div class="english">
        <!-- <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.Lorem
        </p>
        <p>Ipsum has been the industry.</p> -->
      </div>
    </div>
    <div class="journalism">
      <div class="news" v-for="(New, n) in list" :key="n">
        <div v-if="n < 3" class="jour-new">
          <img :src="axios.defaults.baseURL + New.coverImg" alt="" />
          <div class="jour-info">
            <div class="nadetail">
              <p class="name">
                {{ New.title }}
              </p>
              <p class="date">{{ New.releaseTime }}</p>
              <p class="intro">{{ New.introduce }}</p>
              <!-- <p class="intro">{{ New.particulars }}</p> -->
            </div>
            <div class="more">
              <router-link
               target='_blank'
                tag="a"
                :class="{
                  button: true,
                  second: n == 1,
                }"
                :to="{
                  name: 'ProDetail',
                  params: {
                    id: New.navMenuId,
                    infoId: New.id,
                  },
                }"
              >
                more
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.getHNewList(); //生命周期创建时调用axios函数
  },

  data() {
    return {
      list: [],
    };
  },
  methods: {
    getHNewList() {
      this.axios
        .get("/api/nav/menu/info/listAllTop?navMenuId=3")
        .then((res) => {
          this.list = res.data.list;
          for (var i = 0; i < this.list.length; i++) {
            //平台资讯显示长度截取
            if (this.list[i].introduce.length > 40) {
              this.list[i].introduce =
                this.list[i].introduce.slice(0, 40) + "...";
            }
            if (this.list[i].particulars.length > 40) {
              this.list[i].particulars =
                this.list[i].particulars.slice(0, 40) + "...";
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.news-area {
  width: 1286px;
  height: 520px;
  padding-top: 550px;
  border-bottom: 1px solid #f0f0f0;
  margin: 0 auto;
}

.news-area .title {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  margin-top: 60px;
}

.news-area .title .chinese {
  font-size: 24px;
}
.news-area p {
  white-space: pre-wrap;
}

.news-area .title .english {
  margin-top: 10px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
}

.news-area .title .english p {
  margin-top: 5px;
}
.journalism {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.news {
  width: 30%;
  padding: 10px;
}
.jour-new {
  width: 100%;
}
.jour-new img {
  width: 100%;
  height: 180px;
}
.jour-info {
  padding-top: 20px;
  width: 100%;
}
.nadetail {
  height: 70px;
}
.jour-info .name {
  white-space: normal;
  color: #000;
  font-size: 14px;
}
.jour-info .date {
  font-size: 10px;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.3);
}
.jour-info .intro {
  font-size: 10px;
  width: 380px;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.6);
}
.jour-info .more {
  width: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}
.more .button {
  padding: 16px 30px 10px 30px;
  background-color: #208bcf;
  width: 409.8px;
  text-align: center;
  height: 20px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  cursor: pointer;
}
.more .button.second {
  background-color: rgba(0, 185, 150);
}
.more .news-trends {
  padding: 0px;
  display: flex;
  justify-content: space-between;
  width: 40%;
}
.more .news-trends img {
  width: 8px;
  cursor: pointer;
  height: 40px;
  color: rgba(255, 255, 255, 0.7);
  filter: drop-shadow(#ffffff 0px 0);
}
</style>

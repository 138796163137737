<template>
  <div class="home-pageImg" id="homepageImg" :style="homepageImg">
    <!-- <div class="pageImg-info" id="info">
      <div class="close" v-on:click="check()">
        <img src="../../assets/img/close_icon.png" alt="" />
      </div>
      <transition-group tag="div" name="slides" class="banner-slides">
        <div
          class="slide"
          v-for="(item, zindex) in list"
          :key="zindex"
          v-show="zindex == mark"
        >
          <p class="name">{{ item.title }}</p>
          <p class="prize">{{ item.introduce }}</p>
          <router-link
            tag="p"
            class="button"
            :to="{
              name: 'ProDetail',
              params: {
                id: item.navMenuId,
                infoId: item.id,
              },
            }"
          >
            more
          </router-link>
        </div>
      </transition-group>
      <div class="list-area">
        <div
          v-for="(items, index) in list"
          :key="index"
          :class="{
            list: true,
            active: mark == index,
          }"
          @click="handleBanner(index)"
        ></div>
      </div>
    </div> -->
  </div>
</template>


<script>
export default {
  data() {
    return {
      timer: null,
      mark: 0,
      list: [],
      id: null,
      listimg: [],
      coverImg: "",
      homepageImg: {
        backgroundImage: "",
      },
    };
  },

  created() {
    this.getId();
    this.getHpageImgList(); //生命周期创建时调用axios函数
  },
  mounted() {
    this.play(); //生命周期载入后调用
  },

  methods: {
    getId() {
      this.axios
        .get("/api/nav/menu/listAll")
        .then((res) => {
          this.id = res.data.list[0].id;
          this.getHpageImgList(this.id);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getHpageImgList() {
      this.axios
        .get("/api/nav/menu/info/" + this.id)
        .then((re) => {
          this.coverImg = re.data.info.coverImg;
          this.homepageImg.backgroundImage =
            "url(" + this.axios.defaults.baseURL + this.coverImg + ")";
        })
        .catch((err) => {
          console.log(err);
        });
      this.axios
        .get("/api/nav/menu/info/listAll?navMenuId=3")
        .then((res) => {
          this.list = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    autoBanner() {
      this.mark++;
      if (this.mark === this.list.length) {
        this.mark = 0;
        return;
      }
    },
    play() {
      this.timer = setInterval(this.autoBanner, 3000); //定时器
    },
    handleBanner(index) {
      clearInterval(this.timer);
      this.mark = index;
      this.play();
    },
    check() {
      document.getElementById("info").style.display = "none";
    },
  },
};
</script>

<style scoped>
.home-pageImg {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  z-index: 100;
  height: 700px;
  /* background-image: url("../../assets/img/banner27.jpg"); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
}

.home-pageImg .pageImg-info {
  width: 937px;
  height: 484px;
  background-color: rgb(97, 95, 95, 0.7); /* 背景颜色透明度 */
  margin: 0 auto;
  margin-top: 118px;
  text-align: center;
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* Khtml内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
}
.close {
  width: 40px;
  height: 40px;
  float: right;
  cursor: pointer;
}
.close img {
  width: 40px;
}
.slide {
  margin-top: 130px;
  text-align: center;
}
.slide .name {
  color: white;
  font-size: 38px;
  margin-left: 260px;
  font-weight: 530;
  width: 400px;
}
.slide .prize {
  color: rgba(255, 255, 255, 0.7);
  width: 300px;
  padding-top: 10px;
  margin-left: 310px;
  height: 48px;
  overflow: hidden;
}
.button {
  margin-top: 10px;
  width: 150px;
  color: rgba(255, 255, 255, 0.7);
  padding: 18px;
  font-size: 18px;
  margin-left: 40%;
  background-color: #208bcf;
  cursor: pointer;
  text-align: center;
}
.banner-slides {
  widows: 100%;
  height: 400px;
  position: relative;
  margin-top: 40px;
  /* box-sizing: border-box; */
  overflow: hidden;
}

.home-pageImg .list-area {
  width: 100px;
  height: 30px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 80px;
}

.home-pageImg .list-area .list {
  margin-top: 80px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: rgba(213, 219, 218, 0.733);
  cursor: pointer;
}
.home-pageImg .list-area .list.active {
  width: 16px;
  height: 16px;
  background-color: #208bcf;
}

.slides-enter-active {
  transform: translateX(0);
  transition: all 1s ease;
}

.slides-leave-active {
  transform: translateX(-100%);
  transition: all 1s ease;
}

.slides-enter {
  transform: translateX(100%);
}

.slides-leave {
  transform: translateX(0);
}
</style>

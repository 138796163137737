<template>
  <div class="contact-area">
    <form class="input-area">
      <img src="../../assets/img/touch.png" />
      <!-- <div class="touchwx">
        <div class="touchImg">
          <img src="../../assets/img/WeChat2.png" alt="" />
        </div>
        <div class="touchNum">
          <p>177 9035 6695（WeChat）</p>
        </div>
      </div>
      <div class="touch">
        <div class="touchImg">
          <img src="../../assets/img/QQ2.png" alt="" />
        </div>
        <div class="touchNum">
          <p>3051810788（QQ）</p>
          <p>3390256523（QQ）</p>
        </div>
      </div>
      <div class="touchwx">
        <div class="touchImg">
          <img src="../../assets/img/location2.png" alt="" />
        </div>
        <div class="touchNum">
          <p>59 Qinglong Road, Mianyang,Sichuan, P.R.China</p>
        </div>
      </div>
      <div class="touch">
        <div class="touchImg">
          <img src="../../assets/img/phone2.png" alt="" />
        </div>
        <div class="touchNum">
          <p>177 9035 6695</p>
          <p>+86-0816-6089723</p>
        </div>
      </div> -->
    </form>
    <div class="map">
      <!-- <p>我们在这里</p> -->
      <div class="img">
        <img src="../../assets/img/baidumap.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.contact-area {
  width: 1286px;
  height: 350px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.contact-area .input-area {
  width: 34%;
  /* padding-top: 30px; */
  height: 100%;
}
.contact-area .input-area img {
  width: 100%;
  /* padding-top: 30px; */
  /* height: 100%; */
}

.touchwx {
  display: flex;
  flex-flow: nowrap;
  margin: auto 0;
  justify-content: flex-start;
}
.touchwx .touchImg img {
  width: 40px;
}
.touchwx .touchNum p {
  width: calc(100%-40px);
  padding-top: 14px;
  padding-left: 20px;
}
.touch {
  display: flex;
  flex-flow: nowrap;
  margin: 26px 0;
  justify-content: flex-start;
}
.touch .touchImg img {
  width: 40px;
}
.touch .touchNum {
  width: calc(100%-40px);
  padding-left: 20px;
  padding-top: 5px;
}
.contact-area .map {
  width: 66%;
  height: 100%;
}
/* .contact-area .input-area p:first-child {
  font-size: 14px;
  color: #000;
  margin-top: -30px;
} 
.contact-area .input-area p:nth-child(2) {
  font-size: 24px;
  font-weight: 600;
  margin-top: -25px;
} */

.contact-area .input-area p {
  font-size: 16px;
  color: #000;
  font-family: "Times New Roman", Times, serif;
}

.contact-area .map {
  text-align: center;
}

.contact-area .map .img {
  width: 100%;
  /* height: 65%; */
  margin-top: 30px;
  overflow: hidden;
}

.contact-area .map .img img {
  width: 100%;
  height: 250px;
  object-fit: fill;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tuijian"},[_vm._m(0),_c('div',{staticClass:"product"},_vm._l((_vm.list),function(item){return _c('div',{key:item.name,staticClass:"product-item"},[_c('router-link',{staticClass:"img",attrs:{"tag":"div","to":{
          name: 'Product',
          params: {
            id: item.id,
          },
        }}},[_c('img',{attrs:{"src":_vm.axios.defaults.baseURL + item.icon,"alt":""}}),_c('div',{class:{
            'product-name': true,
          }},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"product-info"},[_vm._v(" "+_vm._s(item.introductioin)+" ")])])],1)}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info"},[_c('p',{staticClass:"info-chinese"},[_vm._v("Products")])])}]

export { render, staticRenderFns }
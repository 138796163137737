<template>
  <div class="home-header">
    <div class="com">
      <router-link class="company" to="/">
        <div class="ico">
          <!-- <img src="../assets/img/image.jpg" alt="" /> -->
        </div>
        <div class="name">
          <img src="../assets/img/logo.png" />
          <!-- <img src="../assets/img/Logo_Demo3.png" /> -->
          <!-- <p class="nameone">天赫微波</p>
          <p class="nametwo">Sky Hertz</p> -->
        </div>
      </router-link>
    </div>
    <div class="bar">
      <ul class="nav">
        <li v-for="title in list" :key="title.id">
          <router-link v-if="title.url == '/'" :to="title.url">{{
            title.name
          }}</router-link>
          <router-link v-else :to="title.url + '/' + title.id">{{
            title.name
          }}</router-link>
        </li>
        <!-- <li><router-link to="/product">产品中心</router-link></li>
        <li><router-link to="/trends">业界动态</router-link></li>
        <li><router-link to="/about">关于我们</router-link></li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getHTuiList(); //生命周期创建时调用axios函数
  },
  methods: {
    getHTuiList() {
      this.axios
        .get("/api/nav/menu/listAll")
        .then((res) => {
          console.log(res);
          this.list = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
@import url("../assets/css/header.css");
</style>>



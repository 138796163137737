<template>
  <div id="mai">
    <div id="app">
      <header>
        <home-header></home-header>
      </header>
      <main class="main">
        <router-view/>
      </main>
      <footer>
        <home-footer></home-footer>
      </footer>
    </div>
  </div>
</template>

<script>
import HomeHeader from "@/components/header";
import HomeFooter from "@/components/footer";
export default {
  components: {
    HomeHeader,
    HomeFooter,
  },
};
</script>
<style scoped>
@import url("../src/assets/css/reset/reset.css");
@import url("../src/assets/css/reset/index.css");
#mai {
  background-color: #fff;
  width: 100%;
}
#app {
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  width: -webkit-calc(100% - 80px);
  background-color: #fff;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-width: 1366px;
}

.main {
  width: 100%;
  margin: 0 auto;
}
</style>

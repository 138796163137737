<template>
  <div class="video-area">
    <!-- <div class="gray"></div>
    <div class="video">
      <div class="img">
       <img src="../../assets/img/Play Button.svg" alt="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="181"
          height="180"
          viewBox="0 0 181 180"
        >
        </svg>
      </div>
     <video src=""></video>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.video-area {
  width: 100%;
  height: 550px;
  margin: auto;
  left: 0;
  right: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  position: absolute;
  background-image: url("../../assets/img/index03.jpg");
}
/* 
.video-area .gray {
  height: 20px;
  background-color: #f0f0f0;
}

.video-area .video {
  position: relative;
  width: 100%;
  height: 530px;
  margin-top:-20px ;
  background-image: url('../../assets/img/index (2).png');
  background-size: cover;
}
.video-area .video video {
  width: 100%;
  height: 100%;
}

.video-area .video .img {
  cursor: pointer;
  z-index: 999;
  width: 180px;
  height: 180px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -90px;
  margin-top: -90px;
}

.video-area .video .img svg {
  width: 100%;
  object-fit: fill;
} */
</style>

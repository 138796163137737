import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

//在main.js引入qs
import qs from 'qs'
Vue.use(VueAxios, axios);
axios.defaults.baseURL = 'http://www.skysspa.com/';

Vue.prototype.$axios = axios //全局注册，使用方法为:this.$axios,挂载组件
    //配全局属性配置，在任意组件内可以使用this.$qs获取qs对象
Vue.prototype.$qs = qs

//Vue.config.productionTip = false
//是阻止显示生产模式的消息。如果没有这行代码，或者设置为true，
//控制台就会多出这么一段代码。大概意思就是：你运行的
//Vu是开发模式。为生产部署时，请确保启用生产模式。

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
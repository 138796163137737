<template>
  <div class="tuijian">
    <div class="info">
      <p class="info-chinese">Products</p>
      <!-- <p>
        Lorem Ipsum is simply dummy text of the priting and typesetting
        industry.Lorem
      </p>
      <p></p>
      <p>Ipsum has been the industry</p> -->
    </div>
    <div class="product">
      <div class="product-item" v-for="item in list" :key="item.name">
        <router-link
          tag="div"
          class="img"
          :to="{
            name: 'Product',
            params: {
              id: item.id,
            },
          }"
        >
          <img :src="axios.defaults.baseURL + item.icon" alt="" />
          <div
            :class="{
              'product-name': true,
            }"
          >
            {{ item.name }}
          </div>
          <div class="product-info">
            {{ item.introductioin }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getHTuiList(); //生命周期创建时调用axios函数
  },
  methods: {
    getHTuiList() {
      this.axios
        .get("/api/product/category/listAll")
        .then((res) => {
          this.list = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>


<style scoped>
template {
  text-align: center;
}
.tuijian {
  width: 1286px;
  height: 350px;
  margin: 0px auto;
  margin-top: 700px;
  border-bottom: 1px solid #f0f0f0;
  text-align: center;
  overflow: hidden;
}

.tuijian .info {
  margin-top: 60px;
  text-align: center;
}

.tuijian .info p {
  font-size: 10px;
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.4);
}

.tuijian .info .info-chinese {
  margin-bottom: 40px;
  font-size: 26px;
  color: rgba(0, 0, 0, 0.7);
}

.tuijian .product {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tuijian .product .product-item {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tuijian .product .product-item .img {
  text-align: center;
  width: 200px;
  height: 50px;
  cursor: pointer;
  /* overflow: hidden; */
}
.tuijian .product .product-item .img img {
  height: 100%;
  object-fit: fill;
}

.tuijian .product .product-item .product-info {
  font-size: 12px;
  margin-top: 10px;
  line-height: 20px;
  width: 200px;
  text-align: center;
}

.tuijian .product .product-item .product-name {
  margin-top: 20px;
  color: #000;
  font-size: 16px;
  /* font-weight: 700; */
}
.tuijian .product .product-item .product-name.fontWeight {
  font-weight: 700;
}
</style>
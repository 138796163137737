<template>
  <div class="home">
    <home-img></home-img>
    <home-tui></home-tui>
    <home-product></home-product>
    <home-video></home-video>
    <home-news></home-news>
    <home-contact></home-contact>
  </div>
</template>

<script>
import HomeImg from "@/components/Home/pageImg";
import HomeTui from "@/components/Home/tui";
import HomeProduct from "@/components/Home/product.vue";
import HomeVideo from "@/components/Home/video.vue";
import HomeNews from "@/components/Home/news.vue";
import HomeContact from "@/components/Home/contact.vue";
export default {
  name: "Home",
  data() {
    return {};
  },
  components: {
    HomeImg,
    HomeTui,
    HomeProduct,
    HomeVideo,
    HomeNews,
    HomeContact,
  },
};
</script>

<style scoped>
.home {
  width: 100%;
  margin: auto;
}
</style>

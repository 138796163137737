<template>
  <div class="product-area">
    <div class="product" v-for="(item, hpIndex) in list" :key="item.id">
      <div class="product-info" v-if="hpIndex%2!=0">
        <div
          :class="{
            'product-name': true,
            color: hpIndex%2!=0,
          }"
        >
          <p class="mainName">{{ item.name }}</p>
          <!-- <p class="decorateName">{{ item.introduce }}</p> -->
        </div>
        <div class="product-text">
          {{ item.introduce }}
        </div>
        <router-link
          class="product-about"
          tag="div"
          :to="{
            name: 'Product',
            params: {
              id: item.categoryId,
              nId: item.id,
            },
          }"
        >
          more
        </router-link>
      </div>
      <div class="product-img">
        <img :src="axios.defaults.baseURL + item.previewImg" alt="" />
      </div>
      <div class="product-info" v-if="hpIndex%2==0">
        <div
          :class="{
            'product-name': true,
            color: hpIndex%2== 0,
          }"
        >
          <p class="mainName">{{ item.name }}</p>
          <!-- <p class="decorateName">{{ item.introduce }}</p> -->
        </div>
        <div class="product-text">
          {{ item.introduce }}
        </div>
        <router-link
          class="product-about"
          tag="div"
          :to="{
            name: 'Product',
            params: {
              id: item.categoryId,
              nId: item.id,
            },
          }"
        >
          more
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getHProList(); //生命周期创建时调用axios函数
  },
  methods: {
    getHProList() {
      this.axios
        .get("/api/product/listPush", {
          params: {
            page: 1,
            // limit: "3",
          },
        })
        .then((res) => {
          this.list = res.data.page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.product-area {
  width: 1286px;
  margin: auto;
}
.product {
  width: 1286px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 200px;
}

.product .product-img {
  float: right;
  width: 48%;
  height: 80%;
  line-height: 1;
}

.product .product-img img {
  width: 100%;
  object-fit: fill;
}

.product .product-info {
  width: 48%;
}

.product .product-info .product-name {
  widows: 100%;
  color: #000;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 38px;
  line-height: 50px;
  margin-bottom: 20px;
}
.product .product-info .product-name .mainName {
  white-space: nowrap;
  text-align: left;
}

.product .product-info .product-name.color {
  color: #208bcf;
}

.product .product-info .product-text {
  width: 90%;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

.product .product-info .product-about {
  padding: 20px 18px 20px 18px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.9);
  background-color: #208bcf;
  width: 100px;
  text-align: center;
  cursor: pointer;
}
</style>

<template>
  <div class="home-footer">
    <p>
      <span class="name">SKY WAVE CORP&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2023022067号</a></span>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
template {
  text-align: center;
}
.home-footer {
  width: 100%;
  height: 40px;
  margin: auto;
  /* height: 80px; */
  background-color: #208bcf;
  color: rgba(255, 255, 255, 0.5);
  /* opacity:0.8; */
  overflow: hidden;
  text-align: center;
  position: relative;
  background-size: 100% 120px;
}

.home-footer p {
  text-align: center;
  font-size: 16px;
  color: white;
  padding: 20px;
  margin-bottom: 0px;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-area"},_vm._l((_vm.list),function(item,hpIndex){return _c('div',{key:item.id,staticClass:"product"},[(hpIndex%2!=0)?_c('div',{staticClass:"product-info"},[_c('div',{class:{
          'product-name': true,
          color: hpIndex%2!=0,
        }},[_c('p',{staticClass:"mainName"},[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"product-text"},[_vm._v(" "+_vm._s(item.introduce)+" ")]),_c('router-link',{staticClass:"product-about",attrs:{"tag":"div","to":{
          name: 'Product',
          params: {
            id: item.categoryId,
            nId: item.id,
          },
        }}},[_vm._v(" more ")])],1):_vm._e(),_c('div',{staticClass:"product-img"},[_c('img',{attrs:{"src":_vm.axios.defaults.baseURL + item.previewImg,"alt":""}})]),(hpIndex%2==0)?_c('div',{staticClass:"product-info"},[_c('div',{class:{
          'product-name': true,
          color: hpIndex%2== 0,
        }},[_c('p',{staticClass:"mainName"},[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"product-text"},[_vm._v(" "+_vm._s(item.introduce)+" ")]),_c('router-link',{staticClass:"product-about",attrs:{"tag":"div","to":{
          name: 'Product',
          params: {
            id: item.categoryId,
            nId: item.id,
          },
        }}},[_vm._v(" more ")])],1):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }